<template>
  <div>
      <v-card flat class="text-center d-flex justify-center align-center" style="height:100vh">
        <v-row>
          <v-col cols="12" class="pa-0 ma-0">
            <v-icon color="primary" size="100" @click="Bored()">mdi-emoticon-sad-outline</v-icon>
          </v-col>
          <v-col cols="12" class="pa-0 ma-0">
            <h1 class="display-4 mb-1">Oops!</h1>
          </v-col>
          <v-col cols="12" class="pa-0 ma-0">
            <p class="title">{{$t("We can't seem to find the page you're looking for.")}}</p>
          </v-col>
          <v-col cols="12" class="pa-0 ma-0">
            <v-btn depressed :to="{name:'dashboard'}">{{$t("HOMEPAGE")}}</v-btn>
          </v-col>
        </v-row>
      </v-card>
  </div>
</template>
<script>
export default {
  methods:{
    Bored(){
      this.$router.push({name:'bored'})
    }
  }
}
</script>